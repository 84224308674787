import HardwareStatus from "./HardwareStatus";


export class SupervisionWarehouse {
  warehouse_idx: number = 0;
  sweep_idx: number = 0;
  warehouse_name: string = "";
  counting_number: string = "";
  reading_percentage: number = 0;
  effective_percentage: number = 0;
  devices_count: number = 0;
  read_positions: number = 0;
  effective_readings:  number = 0;
  total_count: number = 0;
  is_processing:boolean=false;

  public static from(json: any) {
    return Object.assign(new SupervisionWarehouse(), json) as SupervisionWarehouse;
  }
}

export class SupervisionHistory {
  warehouse_idx: number = 0;
  sweep_idx: number = 0;
  warehouse_name: string = "";
  counting_number: string = "";
  reading_percentage: number = 0;
  effective_percentage: number = 0;
  devices_count: number = 0;
  read_positions: number = 0;
  effective_readings:  number = 0;
  total_count: number = 0;
  is_processing:boolean=false;

  public static from(json: any) {
    return Object.assign(new SupervisionWarehouse(), json) as SupervisionWarehouse;
  }
}

export default class Supervision {
  corridor_name: string = "";
  corridor_idx: number = 0;
  reading_percentage: number = 0;
  effective_percentage: number = 0;
  device_name: string = "";
  battery_level: number = 0;
  read_positions: number = 0;
  effective_readings: number = 0;
  total_count: number = 0;

  public static from(json: any) {
    return Object.assign(new Supervision(), json) as Supervision;
  }
}

export class SupervisionDetails {
  corridor_name: string = "";
  corridor_idx: number = 0;
  total_positions: number = 0;
  read_positions: number = 0;
  effective_readings: number = 0;
  device_name: string = "";
  hardware_status: HardwareStatus | undefined = undefined;
  positions: Array<supervisionDetailsPosition> = [];

  public static from(json: any) {
    return Object.assign(new SupervisionDetails(), json) as SupervisionDetails;
  }
}

export class supervisionDetailsPosition {
  position_label: string = "";
  pallet_label: string = "";
  state: string = "";
  position_path: string = "";
  pallet_path: string = "";

  public static from(json: any) {
    return Object.assign(new supervisionDetailsPosition(), json) as supervisionDetailsPosition;
  }
}
