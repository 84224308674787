import React, { useCallback, useEffect, useState } from "react";
import { Button, Popover, Spin, Modal, Col, Row, Card } from "antd";
import CorridorService from "../../../../../../services/CorridorService";
import { CorridorShow } from "../../../../../../models/Corridor";
import SweepService from "../../../../../../services/SweepService";
import { SweepPositionShow } from "../../../../../../models/Sweep";
import ImageDisplayer from "../../../../../utils/ImageDisplayer";
import SweepPictureService from "../../../../../../services/PictureService";
import SweepThumbnailService from "../../../../../../services/ThumbnailService";

// Modal component to display images
const ImageModal: React.FC<ImageModalProps> = ({ visible, sweepId, modalPosition, onClose }) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose={true}
      title={`Position: ${modalPosition}`}
    >
      <Row justify="space-around" align="middle">
        <Col span="100px">
          <Card style={{ width: "230px", textAlign: "center", height: "100" }}>
            <ImageDisplayer
              fetchFunction={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return SweepPictureService.getSweepPositionPicture(sweepId, paddedPositionLabel);
              }}
              lowfetchFuntion={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return SweepThumbnailService.getSweepPositionThumbnail(sweepId, paddedPositionLabel);
              }}
              height={"100px"}
            />
          </Card>
        </Col>
        <Col span="100px">
          <Card style={{ width: "230px", textAlign: "center", height: "100" }}>

            <ImageDisplayer
              fetchFunction={() => {
                var paddedPositionLabel = modalPosition.padStart(5, ' ');
                return SweepPictureService.getSweepPalletPicture(sweepId, paddedPositionLabel);
              }}
              lowfetchFuntion={() => {
                const paddedPositionLabel = modalPosition.padStart(5, ' ');
                return SweepThumbnailService.getSweepPalletThumbnail(sweepId, paddedPositionLabel);
              }}
              height={"100px"}
            />
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};
interface ImageModalProps {
  visible: boolean;
  sweepId: number;
  modalPosition: string;
  onClose: () => void;
}
interface GridProps {
  sweepId: string;
  corridorId: number;
}

const CorridorMap: React.FC<GridProps> = ({ corridorId, sweepId }) => {
  const [positions, setPositions] = useState<SweepPositionShow[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [corridor, setCorridor] = useState<CorridorShow>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalPosition, setModalPosition] = useState<string>("");

  const updateContent = useCallback(() => {
    CorridorService.getOne(corridorId.toString()).then((corridor) => {
      if (corridor === null) {
        return;
      }
      SweepService.getSweepPositionsByCorridor(sweepId, corridor.name).then(
        (positions) => {
          if (positions === null || corridor === undefined) {
            return;
          }
          console.log(corridor.rack_template)
          console.log(positions)
          setCorridor(corridor);
          setPositions(positions);
          setLoading(false);
        }
      );
    });
  }, [sweepId, corridorId]);

  useEffect(() => {
    updateContent();
  }, [updateContent]);

  const handlePopoverClick = useCallback((sweepPosition: SweepPositionShow | undefined) => {
    if (sweepPosition) {
      setModalPosition(sweepPosition.position_label)
      setModalVisible(true);
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          overflow: "scroll"
        }}
      >
        {corridor && corridor.rack_template && (
          Array.from({ length: corridor.rack_template.level_range[1] - corridor.rack_template.level_range[0] + 1 }, (_, i) => (
            <div
              key={`row-${i}`}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {corridor?.rack_template && Array.from({ length: corridor.rack_template.column_range[1] - corridor.rack_template.column_range[0] + 1 }, (_, j) => {
                let colText = corridor.rack_template?.column_range ? String(j + corridor.rack_template.column_range[0]).padStart(2, "0") : '';
                if (corridor.rack_template?.orientation === "right"){
                  colText = corridor.rack_template?.column_range ? String(corridor.rack_template.column_range[1]-j).padStart(2, "0") : '';
                }
                const levelText = corridor.rack_template?.level_range ? String(i + corridor.rack_template.level_range[0]) : '';
                const positionText = corridor.name + colText + levelText;
                const matchingPosition = positions.find((position) => position.position_label === positionText);
                var palletLabel = matchingPosition?.pallet_label ?? "Empty Position";
                palletLabel = matchingPosition?.state === "unknown" ? "Unexplored" : palletLabel;
                const popoverContent = matchingPosition ? `Pallet Label: ${palletLabel}` : "";
                const buttonStyle = {
                  margin: "2px",
                  backgroundColor: "#FF5252",
                };
                if (matchingPosition) {
                  switch (matchingPosition.state) {
                    case "full":
                      buttonStyle.backgroundColor = "#4CAF50"; // Green for "full"
                      break;
                    case "empty":
                      buttonStyle.backgroundColor = "#FFC107"; // Yellow for "empty"
                      break;
                    case "unknown":
                      buttonStyle.backgroundColor = "#2196F3"; // Blue for "unknown"
                      break;
                    default:
                      buttonStyle.backgroundColor = "#FF5252";
                      break;
                  }
                }
                else {
                  buttonStyle.backgroundColor = "#bfbfbf";
                }
                return (
                  <Popover
                    title={`Position: ${positionText}`}
                    key={`tooltip-${i}-${j}`}
                    content={<p>{popoverContent}</p>}
                  >
                    <Button
                      ghost
                      key={`button-${i}-${j}`}
                      type={"primary"}
                      size="small"
                      style={buttonStyle}
                      onClick={() => handlePopoverClick(matchingPosition)}
                    ></Button>
                  </Popover>
                );
              })}
            </div>
          )).reverse()
        )}
        <ImageModal
          visible={modalVisible}
          sweepId={parseInt(sweepId)}
          modalPosition={modalPosition}
          onClose={() => setModalVisible(false)}
        />
      </Row>
    </Spin>
  );
};

export default CorridorMap;
