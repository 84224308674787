import HardwareStatus from "./HardwareStatus";
import Warehouse from "./Warehouse";

export default class PalletDevice {
  id: string = "";
  idx: Number = 0;
  name: string = "";
  warehouse: Warehouse | undefined = undefined;

  public static from(json: any) {
    return Object.assign(new PalletDevice(), json) as PalletDevice;
  }
}

export class PalletDeviceDetails {
  idx: Number = 0;
  name: string = "";
  warehouse: Warehouse | undefined = undefined;
  hardware_status: HardwareStatus | undefined = undefined;

  public static from(json: any) {
    return Object.assign(
      new PalletDeviceDetails(),
      json
    ) as PalletDeviceDetails;
  }
}

export class PalletDeviceModify {
  idx: Number = 0;
  name: string = "";
  warehouse_idx: number = -1;

  public static from(json: any) {
    return Object.assign(
      new PalletDeviceDetails(),
      json
    ) as PalletDeviceDetails;
  }
}

export class PalletDeviceCreate {
  name: string = "";
  warehouse_idx: number = -1;

  public static from(json: any) {
    return Object.assign(new PalletDeviceCreate(), json) as PalletDeviceCreate;
  }
}
export class PalletDeviceConnections {
  idx: number = 0;
  is_connected: boolean = true;
  timestamp: string = "";

  public static from(json: any) {
    return Object.assign(new PalletDeviceConnections(), json) as PalletDeviceConnections;
  }

}
