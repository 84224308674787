import React from 'react';
import { Row, Col, Flex, Tag } from 'antd';
import { SweepPositionShow } from '../../../../../../models/Sweep';
import "./BigPicture.css"
import { WarehousePictureService } from '../../../../../../services/PictureService';
import { WarehouseThumbnailService } from '../../../../../../services/ThumbnailService';
import ImageDisplayer2 from '../../../../../utils/ImageDisplayer2';

interface ImageGridProps {
  subgrid: Array<Array<SweepPositionShow | null>>
  gridSizeX: number
  gridSizeY: number
  warehouseId: number
  windowHeight: number
  windowWidth: number
}

const ImageGrid: React.FC<ImageGridProps> = ({
  subgrid,
  gridSizeX,
  gridSizeY,
  warehouseId,
  windowHeight,
  windowWidth
}) => {
  const getColSpan = () => {
    if (gridSizeX === 5) return 4
    return 24 / gridSizeX;
  };
  const getsize = () => {
    return windowHeight/gridSizeY
  }

  return (
    <Flex gap="middle" align='start' vertical >
      <Flex style={{ width: "100%", flexWrap: "wrap", borderRadius: "6" }} justify='center'>
        {
          subgrid.map((row, rowIndex) => (
            <Row key={rowIndex} gutter={[5, 5]} >
              {
                row.map((position, colIndex) => (
                  <Col key={colIndex} span={getColSpan()}>
                    <Row justify="center">
                      {position && position.position_label &&
                        <ImageDisplayer2
                          positionLabel={position.position_label }
                          fetchFunction={() => {
                            const paddedPositionLabel = position.position_label.padStart(5, ' ');
                            return WarehousePictureService.getWarehousePalletPicture(warehouseId, paddedPositionLabel);
                          }}
                          lowfetchFuntion={() => {
                            const paddedPositionLabel = position.position_label.padStart(5, ' ');
                            return WarehouseThumbnailService.getWarehousePalletThumbnail(warehouseId, paddedPositionLabel);
                          }}
                          width={`${0.75 * (16/9) * getsize()}px`}
                          height={`${0.75 * getsize()}px`}
                        />}
                    </Row>
                    <Row justify="center">
                      <div style={{ backgroundColor: "#F50", alignContent: "center", justifyContent: "center", width: "100%" }} >
                        <Tag
                          color='#f50'
                          style={{ width: '100%', textAlign: 'center' }}
                        >{position?.position_label ? position.position_label : ""}</Tag>
                      </div>
                    </Row>
                  </Col>
                ))
              }
            </Row>
          ))
        }
      </Flex>
    </Flex>
  );
};

export default ImageGrid;
