import { Button, Input, Modal, Select, Space, notification } from "antd";
import { useEffect, useState } from "react";
import { WarehouseSummary } from "../../../../models/Warehouse";
import {
  PalletDeviceDetails,
  PalletDeviceModify,
} from "../../../../models/PalletDevice";
import PalletDeviceService from "../../../../services/PalletDeviceService";

interface CreateModifyModalProps {
  onClose: () => void;
  onUpdateContent: () => void;
  isLoading: boolean;
  palletDevice: PalletDeviceDetails | undefined;
  warehouses: WarehouseSummary[];
  palletDevices: PalletDeviceDetails[];
  isOpen: boolean;
}
const DeviceModifyModal: React.FC<CreateModifyModalProps> = ({
  onClose,
  onUpdateContent,
  isLoading,
  palletDevice,
  warehouses,
  palletDevices,
  isOpen,
}) => {
  const [deviceName, setDeviceName] = useState<string>(
    palletDevice?.name ? palletDevice.name : ""
  );
  const [warehouseIdx, setWarehouseIdx] = useState<number>(
    palletDevice?.warehouse ? palletDevice.warehouse.idx : -1
  );
  const handleWarehouseChange = (value: number) => {
    setWarehouseIdx(value);
  };
  useEffect(() => {
    setDeviceName(palletDevice?.name ? palletDevice.name : "");
    setWarehouseIdx(palletDevice?.warehouse ? palletDevice.warehouse.idx : -1);
  }, [palletDevice?.name, palletDevice?.warehouse]);

  const handleOk = () => {
    const isDuplicateName = palletDevices.some(
      (device) => device.name === deviceName
    );
    if (isDuplicateName && palletDevice?.name !== deviceName) {
      Modal.error({
        title: "Duplicate Name",
        content:
          "A device with the same name already exists. Please choose a different name.",
      });
    } else {
      const device = new PalletDeviceModify();
      device.idx = palletDevice?.idx ? palletDevice.idx : -1;
      device.name = deviceName;
      device.warehouse_idx = warehouseIdx;
      console.log(device);
      PalletDeviceService.modifyOne(device).then((res) => {
        onUpdateContent();
        onClose();
        if (res) {
          notification.success({
            message: "Device edited successfully",
          });
        } else {
          notification.error({
            message: "No changes detected",
          });
        }
      });
    }
  };

  const handleCancel = () => {
    setDeviceName(palletDevice?.name ? palletDevice.name : "");
    setWarehouseIdx(palletDevice?.warehouse ? palletDevice.warehouse.idx : -1);
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceName(event.target.value);
  };

  return (
    <Modal
      title="Modify device"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} shape="round">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
          shape="round"
        >
          Submit
        </Button>,
      ]}
    >
      <Space wrap>
        <Input
          type="text"
          placeholder={deviceName}
          onChange={handleChange}
          name="deviceName"
          value={deviceName}
        />

        <Select
          style={{ width: 120 }}
          value={warehouseIdx}
          onChange={handleWarehouseChange}
          options={warehouses.map((warehouse) => ({
            label: warehouse.name,
            value: warehouse.idx,
          }))}
        />
      </Space>
    </Modal>
  );
};
export default DeviceModifyModal;
