import { notification } from "antd";
import UserService from "../../services/UserService";
import { useNavigate, useSearchParams } from "react-router-dom";

import styles from "./login.module.css";

interface LoginPageProps {
  onSuccess?: (token: string) => void;
}

function LoginPage(props: LoginPageProps) {
  const [searchParams] = useSearchParams();
  let redirectTo = searchParams.get("redirectTo") || false;
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const onFinish = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get("username") as string;
    const password = formData.get("password") as string;

    UserService.login(username, password)
      .then(() => {
        if (redirectTo) {
          navigate(""+redirectTo)
        }
        else {

          UserService.getDefaultView().then((response) => {
            if (response) {
              navigate("/" + response);
            }
          });
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          api.error({
            message: <strong>Incorrect  Credentials</strong>,
            description:
              "User or password incorrect.",
            style: { backgroundColor: "#fff2f0", border: "1px solid #ffccc7" },
          });
        } else {
          api.error({
            message: <strong>Oops! algo salió mal...</strong>,
            description: "Por favor, intente más tarde.",
            style: { backgroundColor: "#fff2f0", border: "1px solid #ffccc7" },
          });
        }
      });
  };

  return (
    <div className={`${styles.mainContainer} container-fluid`}>
      {contextHolder}
      <div className={`${styles.mainRow} row`}>
        <div
          className={`${styles.colLeft} col-6 d-flex flex-column justify-content-center`}
        >
          <div className={`${styles.rowLogo} row justify-content-center`}>
            <div
              className={`${styles.colLogo} col-8 d-flex justify-content-center p-0`}
            >
              <img src="/login_logo.png" alt="Logo" />
            </div>
          </div>
          <div
            className={`${styles.rowWelcome} row justify-content-center mt-4`}
          >
            <div className={`${styles.colWelcome} col-8 p-0`}>
              <h1>Welcome!</h1>
            </div>
          </div>

          <form onSubmit={onFinish}>
            {/* <<<--- User --->>> */}
            <div className={`${styles.rowUser} row mt-3`}>
              <div
                className={`${styles.rowLabelUser} row justify-content-center`}
              >
                <div className={`${styles.colLabelUser} col-6`}>
                  <label className="ps-3" htmlFor="username">
                    User
                  </label>
                </div>
              </div>
              <div
                className={`${styles.rowInputUser} row justify-content-center m-0 p-0 mt-1`}
              >
                <div className={`${styles.colInputUser} col-6`}>
                  <input
                    className="ps-3"
                    name="username"
                    type="text"
                    required
                  />
                </div>
              </div>

              {/* <<<--- Password --->>> */}
              <div
                className={`${styles.rowLabelUser} row justify-content-center mt-3`}
              >
                <div className={`${styles.colLabelUser} col-6`}>
                  <label className="ps-3" htmlFor="password">
                    Password
                  </label>
                </div>
              </div>
              <div
                className={`${styles.rowInputUser} row justify-content-center m-0 p-0 mt-1`}
              >
                <div className={`${styles.colInputUser} col-6`}>
                  <input
                    className="ps-3"
                    name="password"
                    type="password"
                    required
                  />
                </div>
              </div>

              {/* <<<--- Iniciar sesión --->>> */}
              <div
                className={`${styles.rowButton} row justify-content-center mt-5 pt-3`}
              >
                <div
                  className={`${styles.colButton} col-4 d-flex justify-content-center`}
                >
                  <button type="submit">Log in</button>
                </div>
              </div>
            </div>
          </form>
          <div className="row position-absolute bottom-0 start-0">
            <div className="col-5 ms-3 mb-3">
              <img src="/logo_poweredBy.svg" alt="Powered by" />
            </div>
          </div>
        </div>
        <div
          className={`${styles.colRight} col-6 d-flex justify-content-center py-3 px-4`}
        >
          <img src="/login_background.png" alt="Background" />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;


