import { Input, Col, Row, Space } from "antd";
import { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { debounce } from 'lodash';

interface PositionsControlsProps {
  onApply: (
    position: string,
    pallet: string,
    corridor: string,
    column: string,
    level: string
  ) => void;
}

const PositionsControls = (props: PositionsControlsProps) => {
  const [filters, setFilters] = useState({
    position: "",
    pallet: "",
    corridor: "",
    column: "",
    level: ""
  });

  const filtersRef = useRef(filters);

  // Update the ref whenever the filters state changes
  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  // Create a debounced version of the onApply function
  const debouncedApply = useMemo(
    () => debounce(() => {
      const { position, pallet, corridor, column, level } = filtersRef.current;
      props.onApply(position, pallet, corridor, column, level);
    }, 3000),
    [props]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const newFilters = { ...filters, [key]: e.target.value };
    setFilters(newFilters);
    debouncedApply(); // Call the debounced function on input change
  };

  const onApply = useCallback(() => {
    const { position, pallet, corridor, column, level } = filters;
    props.onApply(position, pallet, corridor, column, level);
  }, [filters, props]);

  const fields = [
    { label: "Position", value: filters.position, key: "position" },
    { label: "Pallet", value: filters.pallet, key: "pallet" },
    { label: "Corridor", value: filters.corridor, key: "corridor" },
    { label: "Column", value: filters.column, key: "column" },
    { label: "Level", value: filters.level, key: "level" },
  ];

  return (
    <Row gutter={20}>
      {fields.map((field) => (
        <Col key={field.key}>
          <Space>
            {field.label}
            <Input
              placeholder={field.label}
              value={field.value}
              onChange={(e) => handleInputChange(e, field.key)}
              onPressEnter={onApply}
            />
          </Space>
        </Col>
      ))}
    </Row>
  );
};

export default PositionsControls;
