import React from "react";
import { Col, Layout, Row } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import LeftMenu from "./LeftMenu";

const { Header, Sider, Content } = Layout;

interface AppLayoutProps {
  children?: React.ReactNode;
}

interface AppLayoutState {
  collapsed: boolean;
}

class AppLayout extends React.Component<AppLayoutProps, AppLayoutState> {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed ? this.state.collapsed : undefined}
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            this.setState({
              collapsed: broken,
            });
          }}
          style={{
            minHeight: "100vh",
          }}
        >
          <div className="logo">
            <img
              src="/logo.png"
              style={{
                maxHeight: "90%",
                maxWidth: "90%",
                borderRadius: "5px",
                background: "#FFFFFF",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
              alt={"asd"}
            />
          </div>
          <div>
            <LeftMenu />
          </div>
        </Sider>
        <Layout className="site-layout">
          <Header
            style={{ background: "#114373", padding: 0 }}
            className="site-layout-background"
          >
            <Row justify="space-between">
              <Col>
                {React.createElement(
                  this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: this.toggle,
                  }
                )}
              </Col>
              <Col>
                <div></div>
              </Col>
            </Row>
          </Header>
          <Content
            className="content"
            style={{
              padding: 12,
              minHeight: 280,
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default AppLayout;
