import { Button, Input, Modal, Select, Space } from "antd";
import { useState } from "react";
import { WarehouseSummary } from "../../../../models/Warehouse";
import {
  PalletDeviceCreate,
  PalletDeviceDetails,
} from "../../../../models/PalletDevice";
import PalletDeviceService from "../../../../services/PalletDeviceService";

interface CreateDeviceModalProps {
  onClose: () => void;
  onUpdateContent: () => void;
  isLoading: boolean;
  warehouses: WarehouseSummary[];
  warehouseIdx: number | undefined;
  onSetWarehouseIdx: (value: number | undefined) => void;
  palletDevices: PalletDeviceDetails[];
  isOpen: boolean;
  onWarehouseChange: (value: number) => void;
}
const DeviceCreateModal: React.FC<CreateDeviceModalProps> = ({
  onClose,
  onUpdateContent,
  isLoading,
  warehouses,
  warehouseIdx,
  onSetWarehouseIdx,
  palletDevices,
  isOpen,
  onWarehouseChange,
}) => {

  const [deviceName, setDeviceName] = useState<string>("");

  const handleOk = () => {
    const isDuplicateName = palletDevices.some(
      (device) => device.name === deviceName
    );
    if (isDuplicateName) {
      Modal.error({
        title: "Duplicate Name",
        content:
          "A device with the same name already exists. Please choose a different name.",
      });
    } else {
      const device = new PalletDeviceCreate();
      device.name = deviceName;
      device.warehouse_idx = warehouseIdx ?? 0;
      console.log(device);
      PalletDeviceService.createOne(device).then(() => {
        onClose();
        setDeviceName("");
        onSetWarehouseIdx(undefined);
        onUpdateContent();
      });
    }
  };

  const handleCancel = () => {
    onClose();
    setDeviceName("");
    onSetWarehouseIdx(undefined);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceName(event.target.value);
  };

  return (
    <Modal
      title="Create device"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} shape="round">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
          shape="round"
        >
          Submit
        </Button>,
      ]}
    >
      <Space wrap>
        <Input
          type="text"
          placeholder="Device Name"
          onChange={handleChange}
          name="deviceName"
          value={deviceName}
        />

        <Select
          style={{ width: 120 }}
          onChange={onWarehouseChange}
          options={warehouses.map((warehouse) => ({
            label: warehouse.name,
            value: warehouse.idx,

          }))}
          value={warehouseIdx}
          placeholder="Warehouse"
        />
      </Space>
    </Modal>
  );
};
export default DeviceCreateModal;
