import React from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined, DoubleLeftOutlined, DoubleRightOutlined, VerticalRightOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import RackTemplate from '../../../../../../models/RackTemplate';

interface NavigationArrowsProps {
  onNavigate: (direction: 'left' | 'right' | 'doubleLeft' | 'doubleRight' | 'topLeft' | 'topRight') => void;
  corridorName: string;
  position: { row: number, col: number };
  racktemplate: RackTemplate;
  hideArrows?: { left?: boolean; right?: boolean }; 
}

const NavigationArrows: React.FC<NavigationArrowsProps> = ({ onNavigate, hideArrows = {} }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', height: '100%' }}>
      {!hideArrows.left && (
        <div style={{ display: 'flex', gap: '4px' }}>
          <Button type="primary" icon={<VerticalRightOutlined />} onClick={() => onNavigate('topLeft')} />
          <Button icon={<DoubleLeftOutlined />} onClick={() => onNavigate('doubleLeft')} />
          <Button icon={<LeftOutlined />} onClick={() => onNavigate('left')} />
        </div>
      )}
      {!hideArrows.right && (
        <div style={{ display: 'flex', gap: '4px' }}>
          <Button icon={<RightOutlined />} onClick={() => onNavigate('right')} />
          <Button icon={<DoubleRightOutlined />} onClick={() => onNavigate('doubleRight')} />
          <Button type="primary" icon={<VerticalLeftOutlined />} onClick={() => onNavigate('topRight')} />
        </div>
      )}
    </div>
  );
};

export default NavigationArrows;
