import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import LoginPage from "./components/login/LoginPage";
import PrivateRoutes from "./components/PrivateRoutes";
import Warehouses from "./components/layout/contents/warehouses/Warehouses";
import WarehouseDetails from "./components/layout/contents/warehouses/subcomponents/WarehouseDetails";
import RackTemplates from "./components/layout/contents/racktemplates/RackTemplates";
import RackTemplateCreate from "./components/layout/contents/racktemplates/subcomponents/RackTemplateCreate";
import Devices from "./components/layout/contents/devices/Devices";
import Default from "./components/layout/contents/default/Default";
// import SettingsDetails from "./components/layout/contents/settings/SettingsDetails";
import Sweeps from "./components/layout/contents/sweeps/Sweeps";
import SweepDetails from "./components/layout/contents/sweeps/subcomponents/SweepDetails";
import SweepRevision from "./components/layout/contents/sweeps/revision/SweepRevision";
import Supervision from "./components/layout/contents/supervision/Supervision";
import CorridorDetail from "./components/layout/contents/supervision/subcomponents/CorridorDetail";
import Corridor from "./components/layout/contents/supervision/subcomponents/Corridor";
import SupervisionsHistory from "./components/layout/contents/supervision/subcomponents/history/SupervisionHistory";
import CorridorHistory from "./components/layout/contents/supervision/subcomponents/history/CorridorHistory";
import CorridorHistoryDetail from "./components/layout/contents/supervision/subcomponents/history/CorridorHistoryDetail";
import "bootstrap/dist/css/bootstrap.min.css";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route element={<PrivateRoutes />}>
          <Route
            path="*"
            element={
              <AppLayout>
                <Routes>
                  <Route path="*" element={<Default />} />
                  <Route path="sweeps">
                    <Route path="" element={<Sweeps />} />
                    <Route path=":sweepId" element={<SweepDetails />} />
                    <Route path=":sweepId/revision/" element={<SweepRevision />} />
                  </Route>
                  <Route path="warehouses">
                    <Route path="" element={<Warehouses />} />
                    <Route path=":warehouseId" element={<WarehouseDetails />} />
                  </Route>
                  <Route path="racktemplates">
                    <Route path="" element={<RackTemplates />} />
                    <Route path="create" element={<RackTemplateCreate />} />
                  </Route>
                  <Route path="devices">
                    <Route path="" element={<Devices />} />
                  </Route>
                  <Route path="supervision">
                    <Route path="" element={<Supervision />} />
                    <Route path=":warehouseIdx/" element={<Corridor />} />
                    <Route path=":warehouseIdx/:sweepIdx/:corridorIdx" element={<CorridorDetail />} />
                    <Route path="history/:warehouseIdx" element={<SupervisionsHistory />} />
                    <Route path="history/:warehouseIdx/:sweepIdx" element={<CorridorHistory />} />
                    <Route path="history/:warehouseIdx/:sweepIdx/:corridorIdx" element={<CorridorHistoryDetail />} />
                  </Route>
                  {/* <Route path="settings">
                    <Route path="" element={<SettingsDetails />} />
                  </Route> */}
                </Routes>
              </AppLayout>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
