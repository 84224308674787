import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {  Card, Spin, Tabs, TabsProps } from "antd";
import { useCallback, useEffect, useState } from "react";
import WarehouseShow from "../../../../../models/Warehouse";
import WarehouseService from "../../../../../services/WarehouseService";
import CorridorsTable from "./tab1/CorridorsTable";
import Positions from "./tab2/Positions";
import WarehouseMap from "./tab3/WarehouseMap";
import BigPicture from "./tab4/BigPicture";

const WarehouseDetails = () => {
  const { warehouseId } = useParams();
  const [warehouse, setWarehouse] = useState(new WarehouseShow());
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  let tabId = searchParams.get("tab") || "1";
  const navigate = useNavigate();

  const updateContent = useCallback(() => {
    if (warehouseId) {
      WarehouseService.getOne(warehouseId,navigate).then((warehouse) => {
        if (warehouse == null) {
          return;
        }
        setWarehouse(warehouse);
        // RackTemplateService.getAll().then((rackTemplates) => {
        //   if (rackTemplates == null) {
        //     return;
        //   }
        //   setRackTemplateIdx(rackTemplates[0].idx);
        //   setRackTemplates(rackTemplates);
        // });
        setLoading(false);
      });
    }
  }, [warehouseId,navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Corridors",
      children: <CorridorsTable corridors={warehouse.corridors} />,
    },
    {
      key: "2",
      label: "Positions",
      children: (
        <>
          {warehouseId === undefined ? (
            <></>
          ) : (
            <Positions warehouseId={warehouseId} />
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Map",
      children: (
        <>
          {warehouseId === undefined ? (
            <></>
          ) : (
            <WarehouseMap warehouseId={warehouseId} />
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "Big Picture",
      children: (
        <>
          {warehouseId === undefined ? (
            <></>
          ) : (
             <BigPicture warehouseId={warehouseId} warehouse={warehouse}/>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <Spin spinning={loading}>
        <Card
          title={"Warehouse: " + warehouse.name + " - " + warehouse.warehouse_number}
          extra={
            <>
              {/* <Button
                type="primary"
                onClick={showModal}
                icon={<PlusOutlined />}
                shape="round"
              >
                Add
              </Button>
              <CorridorCreate
                onClose={closeModal}
                onUpdateContent={handleUpdateContent}
                isOpen={isModalOpen}
                onRackTemplateChange={handleRackTemplateChange}
                warehouseId={String(warehouseId)}
                warehouse={warehouse}
                loading={loading}
                rackTemplates={rackTemplates}
                rackTemplateIdx={rackTemplateIdx}
              /> */}
            </>
          }
        >
          <Tabs
            items={tabs}
            activeKey={tabId}
            onTabClick={(activeKey) => {
              setSearchParams({ tab: activeKey });
            }}
          />
        </Card>
      </Spin>
    </>
  );
};

export default WarehouseDetails;
