import { Menu, MenuProps, Modal } from "antd";
import {
  AreaChartOutlined,
  DesktopOutlined,
  FormOutlined,
  PoweroffOutlined,
  PullRequestOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import UserService from "../../services/UserService";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const iconMap: { [key: string]: React.ReactNode } = {
  sweep: <PullRequestOutlined />,
  warehouse: <TableOutlined />,
  racktemplate: <FormOutlined />,
  device: <DesktopOutlined />,
  supervision: <AreaChartOutlined />,
  Logout: <PoweroffOutlined />,
};

const labels: { [key: string]: string } = {
  sweep: "Sweeps",
  warehouse: "Warehouses",
  racktemplate: "Rack Templates",
  device: "Devices",
  supervision: "Supervision",
};

const pathMap: { [key: string]: string } = {
  Sweeps: "/sweeps",
  Warehouses: "/warehouses",
  "Rack Templates": "/racktemplates",
  Devices: "/devices",
  Supervision: "/supervision",
  Logout: "",
};

function LeftMenu() {
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = React.useState<boolean>(false);
  const [sideBar, setSideBar] = React.useState<string[]>([]);
  const location = useLocation();
  const firstPathSegment = location.pathname.split("/")[1];
  const selectedKey = sideBar.findIndex(
    (label) => pathMap[labels[label]]?.includes(firstPathSegment)
  ).toString();

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "-1") {
      setShowLogoutModal(true);
      return;
    }
    const path = pathMap[labels[sideBar[Number(e.key)]]];
    if (path) {
      navigate(path);
    }
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    window.location.reload();
    navigate("/");
  };

  const updateContent = useCallback(() => {
    UserService.getSideBar().then((sideBar) => {
      if (sideBar === null) {
        return;
      }
      setSideBar(sideBar);
    });
  }, []);

  useEffect(() => {
    updateContent();
  }, [updateContent]);

  const items = [
    ...sideBar.map((label, index) =>
      getItem(labels[label], index.toString(), iconMap[label])
    ),
    getItem("Logout", "-1", iconMap["Logout"]),
  ];

  return (
    <>
      <Menu
        theme="dark"
        onClick={onClick}
        selectedKeys={[selectedKey]}
        mode="inline"
        items={items}
      />
      <Modal
        title="Logout"
        open={showLogoutModal}
        onOk={logout}
        onCancel={() => setShowLogoutModal(false)}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </>
  );
}

export default LeftMenu;
