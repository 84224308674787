import React from "react";
import { Button, DatePicker, Input, Modal, Select, Space, notification } from "antd";
import { useState } from "react";
import SweepBase, { SweepCreate } from "../../../../../models/Sweep";
import SweepService from "../../../../../services/SweepService";
import { WarehouseSummary } from "../../../../../models/Warehouse";
import { PalletDeviceDetails } from "../../../../../models/PalletDevice";
import dayjs from "dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface CreateSweepsModalProps {
  isVisible: boolean;
  onClose: () => void;
  onUpdateContent: () => void;
  warehouses: WarehouseSummary[];
  devices: PalletDeviceDetails[];
  nowProcessing: number[];
  sweeps: SweepBase[];
  onSubmitLoading:(state:boolean) => void;
}

const SweepsCreate: React.FC<CreateSweepsModalProps> = ({
  isVisible,
  onClose,
  onUpdateContent,
  warehouses,
  devices,
  nowProcessing,
  sweeps,
  onSubmitLoading
}) => {
  const [counting_number, setCounting_number] = useState<string>("");
  const [warehouseIdx, setWarehouseIdx] = useState<number | undefined>(undefined);
  const [devicesIdxs, setDevicesIdxs] = useState<number[]>([]);
  const [timestamp_start, setTimestamp_start] = useState<string>("");
  const [disableButton,setDisableButton]=useState<boolean>(false)
  const [filteredDevices, setFilteredDevices] = useState<PalletDeviceDetails[]>([]);

  const onDevicesChange = (values: number[]) => {
    setDevicesIdxs(values);
  };

  const onWarehouseChange = (value: number) => {
    setDevicesIdxs([]);
    setWarehouseIdx(value);
    const filtered = devices.filter((device) => device.warehouse?.idx === value);
    setFilteredDevices(filtered);
  };

  const handleChangeCounting_number = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCounting_number(event.target.value);
  };

  const handleChangeTimestamp_start = (date: dayjs.Dayjs | null, dateString: string) => {
    const formattedDate = date ? date.format("DD/MM/YYYY") : "";
    setTimestamp_start(formattedDate);
  };

  const resetFields = () => {
    setCounting_number("");
    setWarehouseIdx(undefined);
    setDevicesIdxs([]);
    setTimestamp_start("");
    setFilteredDevices([]);
  };

  const handleOk = () => {
    const isDuplicateCN = sweeps.some(
      (sweep) =>
        String(sweep.counting_number) === counting_number
    );
    if (isDuplicateCN) {
      Modal.error({
        title: "Duplicate Counting Number",
        content:
          "A Sweep with the same counting number already exists. Please choose a different counting number.",
      });
    } else {
      const sweep = new SweepCreate();
      sweep.counting_number = counting_number;
      sweep.warehouse_idx = warehouseIdx ?? 0;
      sweep.devices_idx = devicesIdxs;
      sweep.timestamp_start = timestamp_start;
      console.log(sweep);
      onSubmitLoading(true)
      setDisableButton(true)
      SweepService.create(sweep).then((res) => {
        if (res) {
          setDisableButton(false)
          onSubmitLoading(false)
          notification.success({
            message: "Sweep Created successfully",
          });
          resetFields();
        }
        onUpdateContent();
        onClose();
      });
    };
  };

  const handleCancel = () => {
    resetFields();
    onClose();
  };

  return (
    <Modal
      title="Create Sweeps"
      style={{ display: "flex" }}
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <>
          {nowProcessing.includes(warehouseIdx ?? 0) && <h3 style={{ textAlign: "left", color: "red" }}>
            <ExclamationCircleOutlined />Currently, the warehouse is in operation.</h3>}
          <Button key="back" onClick={handleCancel} shape="round">
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            shape="round"
            disabled={
              !warehouseIdx ||
              devicesIdxs.length === 0 ||
              !counting_number ||
              !timestamp_start ||
              nowProcessing.includes(warehouseIdx ?? 0)||
              disableButton
            }
          >
            Submit
          </Button>
        </>
      ]}
    >
      <Space>
        <Select
          placeholder="Select a Warehouse"
          style={{ width: 180 }}
          onChange={onWarehouseChange}
          options={warehouses.map((warehouse) => ({
            label: warehouse.name,
            value: warehouse.idx,
          }))}
          value={warehouseIdx}
        />
        <Select
          mode="multiple"
          placeholder="Select Devices"
          style={{ width: 180 }}
          showSearch={false}
          onChange={onDevicesChange}
          options={filteredDevices.map((device) => ({
            label: device.name,
            value: device.idx,
          }))}
          value={devicesIdxs}
        />
        <Input
          type="text"
          style={{ width: 180 }}
          placeholder="Counting Number"
          onChange={handleChangeCounting_number}
          value={counting_number}
        />
        <DatePicker
          format="DD/MM/YYYY"
          style={{ width: 150 }}
          onChange={handleChangeTimestamp_start}
          value={timestamp_start ? dayjs(timestamp_start, "DD/MM/YYYY") : null}
        />
      </Space>
    </Modal>
  );
};

export default SweepsCreate;
