import { useCallback, useEffect, useState } from "react";
import PositionsControls from "./PositionsControls";
import PositionsTable from "./PositionsTable";
import Position from "../../../../../../models/Position";
import WarehouseService from "../../../../../../services/WarehouseService";
import { Divider } from "antd";

interface PositionsProps {
  warehouseId: string;
}

interface Filters {
  position:string;
  pallet:string;
  corridor:string;
  column:string;
  level:string;
}

const Positions = (props: PositionsProps) => {
  const [positions, setPositions] = useState<
  Position[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [positionCount, setPositionsCount] = useState<number>(0);
  const [filtersDict, setFiltersDict] = useState<Filters>({"position":"","pallet":"","corridor":"","column":"","level":""});
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const onPageChange = useCallback((page:number, pageSize:number) => {
    setPage(page)
    setPageSize(pageSize)
  }, []);




  const trimSpacesInPositionLabels = (Positions: Position[]): Position[] => {
    return Positions.map(position => {
      position.position_label = position.position_label.trim();
      return position;
    });
  };
  const updateContent = useCallback(() => {
    setLoading(true);

    WarehouseService.getPositionsPaginatedCount(props.warehouseId, filtersDict).then((count) => {
      if (count === null) {
        return;
      }
      WarehouseService.getPositionsPaginated(props.warehouseId, (page-1)*pageSize, pageSize, filtersDict).then((positions) => {
        if (positions === null) {
          return;
        }
          setPositions(trimSpacesInPositionLabels(positions));
          setPositionsCount(count)
          setLoading(false);
      });
    });
  }, [props.warehouseId, filtersDict, page, pageSize]);

  const onApplyFilters = useCallback(
    (
      position: string,
      pallet: string,
      corridor: string,
      column: string,
      level: string
    ) => {
      let newFiltersDict: Filters = filtersDict
      newFiltersDict.position=position
      newFiltersDict.pallet=pallet
      newFiltersDict.corridor=corridor
      newFiltersDict.column=column
      newFiltersDict.level=level
      setFiltersDict(newFiltersDict)
      updateContent()
    },
    [filtersDict, updateContent]
  );

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <>
      <PositionsControls onApply={onApplyFilters} />
      <Divider />
      <PositionsTable positionsCount={positionCount} page={page} pageSize={pageSize} onPageChange={onPageChange}  positions={positions} loading={loading} warehouseId={parseInt(props.warehouseId)}/>
    </>
  );
};

export default Positions;
