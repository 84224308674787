import { DownloadOutlined } from "@ant-design/icons";
import { Image, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";

// Define a cache object
const imageCache: Record<string, string | undefined> = {};
const lowImageCache: Record<string, string | undefined> = {};

interface ImageDisplayerProps {
  fetchFunction: () => Promise<Blob | null>;
  lowfetchFuntion?: () => Promise<Blob | null>;
  style?: React.CSSProperties;
  rotate?: boolean;
  height?: string;
  width?: string;
  positionLabel: string;  // Added this prop to uniquely identify each image
}

function ImageDisplayer2(props: ImageDisplayerProps) {
  const [image, setImage] = useState<string | undefined>(imageCache[props.positionLabel]);
  const [lowImage, setLowImage] = useState<string | undefined>(lowImageCache[props.positionLabel]);
  const [loadingLowImage, setLoadingLowImage] = useState<boolean>(true);

  const defaultStyle: React.CSSProperties = {
    borderRadius: "10px",
    textAlign: "center",
  };

  const updatelowImage = useCallback(async () => {
    if (props.lowfetchFuntion && !lowImageCache[props.positionLabel]) {
      setLoadingLowImage(true);
      const imageBlob = await props.lowfetchFuntion();
      if (imageBlob === null) {
        setLoadingLowImage(false);
        return;
      }
      const imageUrl = URL.createObjectURL(imageBlob);
      setLowImage(imageUrl);
      lowImageCache[props.positionLabel] = imageUrl; // Cache the image
      setLoadingLowImage(false);
      return () => URL.revokeObjectURL(imageUrl);
    } else if (lowImageCache[props.positionLabel]) {
      setLowImage(lowImageCache[props.positionLabel]);
      setLoadingLowImage(false);
    }
  }, [props]);

  const updateImage = useCallback(async () => {
    if (!imageCache[props.positionLabel]) {
      const imageBlob = await props.fetchFunction();
      if (imageBlob === null) return;
      const imageUrl = URL.createObjectURL(imageBlob);
      setImage(imageUrl);
      imageCache[props.positionLabel] = imageUrl; // Cache the image
      return () => URL.revokeObjectURL(imageUrl);
    } else {
      setImage(imageCache[props.positionLabel]);
    }
  }, [props]);

  useEffect(() => {
    updatelowImage();
  }, [updatelowImage]);

  const onDownload = (imgUrl: string | undefined) => {
    if (imgUrl === undefined) {
      return;
    }
    const link = document.createElement<'a'>('a');
    link.href = imgUrl;
    link.download = 'image.jpg';
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(imgUrl);
    link.remove();
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {loadingLowImage && (
        <Spin
          spinning={loadingLowImage}
          style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        />
      )}
      <Image
        src={lowImage}
        style={{
          ...defaultStyle,
          ...props.style,
          transform: props.rotate ? "rotate(90deg)" : undefined,
          opacity: loadingLowImage ? 0 : 1, // Hide image while loading
          transition: "opacity 0.3s ease-in-out", // Smooth transition
        }}
        onLoad={() => setLoadingLowImage(false)} // Stop loading when the image is loaded
        preview={{
          toolbarRender: (actions) => (
            <DownloadOutlined onClick={() => onDownload(image)} />
          ),
          destroyOnClose: true,
          onVisibleChange: () => updateImage(),
          imageRender: () =>
            image ? (
              <Image src={image} preview={false} />
            ) : (
              <Spin spinning={true} />
            ),
        }}
        height={props.height}
        width={props.width}
      />
    </div>
  );
}

export default ImageDisplayer2;
