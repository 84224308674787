import { Table } from "antd";
import { Breakpoint } from "antd";
import { CorridorSummary } from "../../../../../../models/Corridor";
import { useState } from "react";

interface CorridorsTableProps {
  corridors: CorridorSummary[];
  onSort?: (column_key: string, order: string) => void;
}

const CorridorsTable = (props: CorridorsTableProps) => {

  const [pageSize, setPageSize] = useState<number>(10);
  const columns = [
    // {
    //   title: "",
    //   key: "idx",
    //   responsive: ["lg"] as Breakpoint[],
    //   render: (item: CorridorSummary) => item.idx,
    // },
    {
      title: "Corridor Name",
      key: "name",
      responsive: ["lg"] as Breakpoint[],
      render: (item: CorridorSummary) => item.name,
    },
    {
      title: "Total Positions",
      key: "positions",
      responsive: ["lg"] as Breakpoint[],
      render: (item: CorridorSummary) => item.positions.toString(),
    },
    {
      title: "Empty Positions",
      key: "empty_positions",
      responsive: ["lg"] as Breakpoint[],
      render: (item: CorridorSummary) => item.empty_positions.toString(),
    },
    {
      title: "Full Positions",
      key: "full_positions",
      responsive: ["lg"] as Breakpoint[],
      render: (item: CorridorSummary) => item.full_positions.toString(),
    },
    {
      title: "Unexplored Positions",
      key: "unknown_positions",
      responsive: ["lg"] as Breakpoint[],
      render: (item: CorridorSummary) => item.unknown_positions.toString(),
    },
    {
      title: "Rack Template",
      key: "rack_template",
      responsive: ["lg"] as Breakpoint[],
      render: (item: CorridorSummary) => item.rack_template,
    },
    // {
    //   title: "Details",
    //   key: "details",
    //   render: (item: CorridorSummary) => (
    //     <Link to={`${item.idx}`}>
    //       <Button type="primary" icon={<InfoCircleOutlined />} shape="round">
    //         Details
    //       </Button>
    //     </Link>
    //   ),
    // },
  ];

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    if (props.onSort) {
      props.onSort(sorter.columnKey, sorter.order);
    }
  };

  const paginationConfig = {
    pageSize: pageSize,
    total: props.corridors.length,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"],
    onChange: (page: number, pageSize: number | undefined) => {
      if (pageSize) {
        setPageSize(pageSize);
      }
    },
  };

  return (
    <Table
      columns={columns}
      dataSource={props.corridors}
      rowKey="id"
      scroll={{ x: true }}
      size="small"
      pagination={paginationConfig}
      showSorterTooltip={true}
      onChange={handleTableChange}
    />
  );
};

export default CorridorsTable;