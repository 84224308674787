import { /*Button,*/ Spin, Table } from "antd";
import { SweepPositionShow } from "../../../../../../models/Sweep";
// import { Link } from "react-router-dom";
// import { InfoCircleOutlined } from "@ant-design/icons";
import EditableLabel from "../EditLabel";
import {  useState } from "react";
import ImageDisplayer from "../../../../../utils/ImageDisplayer";
import SweepPictureService from "../../../../../../services/PictureService";
import SweepThumbnailService from "../../../../../../services/ThumbnailService";

interface SummaryTableProps {
  sweepId: number
  positions: SweepPositionShow[];
  onSort?: (column_key: string, order: string) => void;
  onUpdateContent: () => void;
  repeatedPositions: string[];
  sweepStatus: string;
  showPictures:boolean;
}

const SummaryTable = (props: SummaryTableProps) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading] = useState<boolean>(false);

  let columns = [
    {
      title: "Position Label",
      key: "position_label",
      render: (item: SweepPositionShow) => {
        const repeated = props.repeatedPositions.includes(item.position_label);
        return (
          <EditableLabel
            sweepId={props.sweepId ? props.sweepId.toString() : "-1"}
            onUpdateContent={props.onUpdateContent}
            label={item.position_label}
            prev_position_label={item.position_label}
            labelType={"position"}
            editable={false}
            isEmpty={item.state === "empty" ? true : false}
            repeated={repeated}
          />
        );
      },
    },
    {
      title: "Position Image",
      key: "image_position",
      render: (item: SweepPositionShow) => {
        return (
          <ImageDisplayer
            fetchFunction={() => {
              const paddedPositionLabel = item.position_label.padStart(5, " ");
              return SweepPictureService.getSweepPositionPicture(
                props.sweepId,
                paddedPositionLabel
              );
            }}
            lowfetchFuntion={(abortSignal: AbortSignal) => {
              const paddedPositionLabel = item.position_label.padStart(5, " ");
              return SweepThumbnailService.getSweepPositionThumbnail(
                props.sweepId,
                paddedPositionLabel,
                abortSignal
              );
            }}
            height={"50px"}
            width={"88.88px"}
          />
        );
      },
    },
    {
      title: "Pallet Label",
      key: "pallet_label",
      render: (item: SweepPositionShow) => {
        if (item.state === "empty") {
          return (
            <EditableLabel
              editable={props.sweepStatus === "processing"}
              sweepId={props.sweepId ? props.sweepId.toString() : "-1"}
              onUpdateContent={props.onUpdateContent}
              label={"Empty"}
              prev_position_label={item.position_label}
              labelType={"pallet"}
              isEmpty={true}
              repeated={false}
            />
          );
        }
        return (
          <EditableLabel
            editable={props.sweepStatus === "processing"}
            onUpdateContent={props.onUpdateContent}
            label={
              item.pallet_label !== undefined && item.pallet_label !== null
              ? item.pallet_label
              : ""
            }
            prev_position_label={item.position_label}
            labelType={"pallet"}
            isEmpty={false}
            sweepId={props.sweepId.toString()}
            repeated={false}
          />
        );
      },
    },
    {
      title: "Pallet Image",
      key: "image_pallet",
      render: (item: SweepPositionShow) => {
        return (
          <ImageDisplayer
            fetchFunction={() => {
              const paddedPositionLabel = item.position_label.padStart(5, " ");
              return SweepPictureService.getSweepPalletPicture(
                props.sweepId,
                paddedPositionLabel
              );
            }}
            lowfetchFuntion={(abortSignal: AbortSignal) => {
              const paddedPositionLabel = item.position_label.padStart(5, " ");
              return SweepThumbnailService.getSweepPalletThumbnail(
                props.sweepId,
                paddedPositionLabel,
                abortSignal
              );
            }}
            height={"50px"}
            width={"88.88px"}
          />
        );
      },
    },

    {
      title: "State",
      key: "state",
      render: (item: SweepPositionShow) => item.state,
    }
  ];

  if (!props.showPictures) {
    columns = columns.filter(
      (column) => column.key !== "image_position"
    );
  }

  const paginationConfig = {
    pageSize: pageSize,
    total: props.positions.length,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"],
    onChange: (page: number, pageSize: number | undefined) => {
      if (pageSize) {
        setPageSize(pageSize);
      }
    },
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    if (props.onSort) {
      props.onSort(sorter.columnKey, sorter.order);
    }
  };

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={props.positions}
        rowKey="id"
        scroll={{ x: true }}
        size="small"
        pagination={paginationConfig}
        showSorterTooltip={true}
        onChange={handleTableChange}
      />
    </Spin>
  );
};

export default SummaryTable;
