import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { Button, Card, Divider, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SweepBase from '../../../../models/Sweep';
import SweepService from '../../../../services/SweepService';
import SweepsTable from './subcomponents/SweepsTable';
import SweepsCreate from './subcomponents/SweepsCreate';
import WarehouseService from '../../../../services/WarehouseService';
import PalletDeviceService from '../../../../services/PalletDeviceService';
import { WarehouseSummary } from '../../../../models/Warehouse';
import PalletDevice from '../../../../models/PalletDevice';
import SweepsControls from './SweepsControls';
import { useNavigate } from 'react-router-dom';
import PermissionService from '../../../../services/PermissionService';
import UserContext from '../../../../context/UserContext';

const Sweeps: React.FC = () => {
  const  isMobile  = useContext(UserContext).isMobile;
  const [warehouses, setWarehouses] = useState<WarehouseSummary[]>([]);
  const [devices, setDevices] = useState<PalletDevice[]>([]);
  const [sweeps, setSweeps] = useState<SweepBase[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nowProcessing, setNowProcessing] = useState<number[]>([]);
  const [warehouseFilter, setWarehouseFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [countingNumberFilter, setCountingNumberFilter] = useState<string>('');
  const [writePermission, setWritePermissions] = useState<Boolean>(false);
  const navigate = useNavigate();

  const updateContent = useCallback(() => {
    SweepService.getAll(navigate).then((sweeps) => {
      if (sweeps == null) {
        return;
      }
      WarehouseService.getAll(navigate).then((warehouses) => {
        if (warehouses == null) {
          return;
        }
        PalletDeviceService.getAll().then((devices) => {
          if (devices == null) {
            return;
          }
          PermissionService.getSweepWritePermission().then((permission) => {
            setWritePermissions(permission);
            setWarehouses(warehouses);
            setDevices(devices);
            const processing = [];
            for (let sweep of sweeps) {
              if (sweep.warehouse && sweep.status === 'processing') {
                processing.push(sweep.warehouse.idx);
              }
            }
            setNowProcessing(processing);
            setSweeps(sweeps);
            setLoading(false);
          });
        });
      });
    });
  }, [navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const submitLoading = (state: boolean) => {
    setLoading(state);
  };

  const onApplyFilters = useCallback(
    (warehouse: string[], countingNumber: string, status: string[]) => {
      setWarehouseFilter(warehouse);
      setStatusFilter(status);
      setCountingNumberFilter(countingNumber);
    },
    []
  );

  const filteredSweeps = useMemo(() => {
    let filtered = sweeps;
    if (warehouseFilter.length > 0) {
      filtered = filtered.filter((entry) =>
        warehouseFilter.some((warehouse) =>
          entry.warehouse?.name
            .toLocaleUpperCase()
            .includes(warehouse.toLocaleUpperCase())
        )
      );
    }

    if (statusFilter.length > 0) {
      filtered = filtered.filter((entry) =>
        statusFilter.some((status) =>
          entry.status.toLocaleUpperCase().includes(status.toLocaleUpperCase())
        )
      );
    }

    if (countingNumberFilter !== '') {
      filtered = filtered.filter((entry) =>
        entry.counting_number
          .toLocaleUpperCase()
          .includes(countingNumberFilter.toLocaleUpperCase())
      );
    }
    return filtered;
  }, [sweeps, warehouseFilter, countingNumberFilter, statusFilter]);

  return (
    <Spin spinning={loading}>
      <Card
        title="Sweeps"
        extra={
          <Button
            type="primary"
            onClick={showModal}
            icon={<PlusOutlined />}
            shape="round"
            disabled={!writePermission}
            style={{ margin: isMobile ? '0 5px' : '0' }}
          >
            New
          </Button>
        }
        style={{
          margin: isMobile ? '10px' : '20px',
          padding: isMobile ? '10px' : '20px',
        }}
      >
        <SweepsCreate
          isVisible={isModalOpen}
          onClose={closeModal}
          onUpdateContent={updateContent}
          warehouses={warehouses}
          devices={devices}
          nowProcessing={nowProcessing}
          sweeps={sweeps}
          onSubmitLoading={submitLoading}
        />
        <SweepsControls
          onApply={onApplyFilters}
          warehouses={warehouses}
        />
        <Divider />
        <SweepsTable
          writePermission={writePermission}
          sweeps={filteredSweeps}
          onUpdateContent={updateContent}
          isMobile={isMobile}
        />
      </Card>
    </Spin>
  );
};

export default Sweeps;
