import React from "react";
import { Col, Progress, Row, Spin, Tag } from "antd";

import {
  WifiOutlined,
  ClockCircleOutlined,
  ApiOutlined,
  DatabaseOutlined,
  BuildOutlined,
  CodeSandboxOutlined,
} from "@ant-design/icons";

import HardwareStatus from "../../../../../models/HardwareStatus";

interface HardwareInfoProps {
  hardwareStatus: HardwareStatus;
  loading: boolean;
  parentWidth: number;
  parentHeight: number;
}


const HardwareInfo: React.FC<HardwareInfoProps> = ({ hardwareStatus, loading, parentHeight, parentWidth }) => {

  const connectionStatusTag = (status: string) => {
    let icon, color;
    switch (status) {
      case "connected":
        icon = <WifiOutlined />;
        color = "#87d068";
        break;

      case "disconnected":
        icon = <WifiOutlined />;
        color = "#f50";
        break;

      case "connecting":
      default:
        icon = <ClockCircleOutlined />;
        color = "processing";
        break;
    }
    return <Tag style={{ fontSize: "0.85vw" }} icon={icon} color={color}></Tag>;
  };

  const batteryStatusTag = (status: boolean) => {
    const icon = <ApiOutlined />;
    const color = status ? "#87d068" : "#f50";
    return <Tag style={{ fontSize: "0.85vw" }} icon={icon} color={color}></Tag>;
  };

  const { general, connection, battery } = hardwareStatus;

  return (

    <Spin spinning={loading}>
      <Row gutter={[10, 10]} justify="center" align="middle" style={{ overflow: "hidden"}} >
        <Col span={4} >
          <b style={{ fontSize: "0.90vw" }}>Disk:</b>
        </Col>
        <Col span={3}>
          <Tag style={{ fontSize: "0.85vw" }} icon={<DatabaseOutlined />} color={"#114373"}></Tag>
        </Col>
        <Col span={17}>
          <Progress percent={parseFloat((general.disk_status * 100).toFixed(2))} strokeLinecap="butt" steps={10} size={[parentWidth  /17- 7, parentHeight /20]} />
        </Col>
        <Col span={4}>
          <b style={{ fontSize: "0.90vw" }}>RAM:</b>
        </Col>
        <Col span={3}>
          <Tag style={{ fontSize: "0.85vw" }} icon={<BuildOutlined />} color={"#114373"}></Tag>
        </Col>
        <Col span={17}>
          <Progress percent={parseFloat((general.ram_status * 100).toFixed(2))} strokeLinecap="butt" steps={10} size={[parentWidth  /17- 7, parentHeight /20]} />
        </Col>
        <Col span={4}>
          <b style={{ fontSize: "0.90vw" }}>CPU:</b>
        </Col>
        <Col span={3}>
          <Tag style={{ fontSize: "0.85vw", padding: "-2px" }} icon={<CodeSandboxOutlined />} color={"#114373"} ></Tag>
        </Col>
        <Col span={17}>
          <Progress percent={parseFloat((general.cpu_status * 100).toFixed(2))} strokeLinecap="butt" steps={10} size={[parentWidth  /17- 7, parentHeight /20]} />
        </Col>
        <Col span={4}>
          <b style={{ fontSize: "0.90vw" }}>Wi-Fi:</b>
        </Col>
        <Col span={3}>{connectionStatusTag(connection.connection_status)}</Col>
        <Col span={17}>

          <Progress percent={parseFloat((connection.signal_strength * 100).toFixed(2))} strokeLinecap="butt" steps={10} size={[parentWidth  /17- 7, parentHeight /20]} />
        </Col>
        <Col span={4}>
          <b style={{ fontSize: "0.90vw" }}>Battery:</b>
        </Col>
        <Col span={3}>{batteryStatusTag(battery.is_charging)}</Col>
        <Col span={17}>
          <Progress percent={parseFloat((battery.battery_level * 100).toFixed(2))} strokeLinecap="butt" steps={10} size={[parentWidth  /17- 7, parentHeight /20]} />
        </Col>
      </Row>
    </Spin>

  );
};

export default HardwareInfo;
