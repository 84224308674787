import { Input, Col, Row, Space, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import { WarehouseSummary } from "../../../../models/Warehouse";

interface PositionsControlsProps {
  onApply: (
    warehouse: string[],
    countingNumber: string,
    status: string[],
  ) => void;
  warehouses: WarehouseSummary[];
}

const SweepsControls = (props: PositionsControlsProps) => {
  const [warehouses, setWarehouses] = useState<WarehouseSummary[]>([]);
  const [warehouse, setWarehouse] = useState<string[]>([]);
  const [countingNumber, setCountingNumber] = useState<string>("");
  const [status, setStatus] = useState<string[]>([]);

  const onApply = useCallback(() => {
    props.onApply(warehouse, countingNumber, status);
  }, [props, warehouse, countingNumber, status]);

  useEffect(() => {
    onApply();
  }, [onApply]);

  const updateContent = useCallback(() => {
    setWarehouses(props.warehouses)
  }, [props.warehouses]);
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);
  const onWarehouseChange = (values: string[]) => {
    setWarehouse(values);
  };
  const onStatusChange = (values: string[]) => {
    setStatus(values);
  };


  return (
    <Row gutter={20}>
      <Col>
        <Space>
          Warehouse
          <Select
            mode="multiple"
            placeholder="warehouses"
            style={{ minWidth: 170, width: 150 * warehouse.length, maxWidth: 450 }}
            onChange={onWarehouseChange}
            showSearch={false}
            options={warehouses.map((warehouse) => ({
              label: warehouse.name,
              value: warehouse.name,
            }))}
          />
        </Space>
      </Col>
      <Col>
        <Space>
          Counting Number
          <Input
            type="text"
            placeholder="Counting Number"
            value={countingNumber}
            onChange={(e) => setCountingNumber(e.target.value)}
          />
        </Space>
      </Col>
      <Col>
        <Space>
          Status
          <Select
            mode="multiple"
            placeholder="Status"
            style={{ minWidth: 135, width: 120 * status.length, maxWidth: 450 }}
            onChange={onStatusChange}
            showSearch={false}
            options={[
              { value: 'processed', label: 'Processed' },
              { value: 'processing', label: 'Processing' },
              { value: 'stopped', label: 'Cancelled' }
            ]}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default SweepsControls;
