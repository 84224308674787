import axios, { AxiosResponse } from "axios";
import UserService from "./UserService";
import Supervision, { SupervisionDetails, SupervisionHistory, SupervisionWarehouse } from "../models/Supervision";
import { NavigateFunction } from "react-router-dom";
import { notification } from "antd";


const API_BASE_URL = window._env_.REACT_APP_ARCOR_CENTRAL_API_URL;
const API_RESOURCE = "supervision/";


export default class SupervisionService {
  public static async getSupervisionWarehouses(): Promise<SupervisionWarehouse[]|null> {
    try {
      const response: AxiosResponse<SupervisionWarehouse[]> = await axios.get(
        API_BASE_URL + API_RESOURCE,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const supervisionWarehouse = response.data
      return supervisionWarehouse;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getSupervisionWarehouse(warehouse_idx: number,navigate:NavigateFunction): Promise<SupervisionWarehouse|null> {
    try {
      const response: AxiosResponse<SupervisionWarehouse> = await axios.get(
        API_BASE_URL + API_RESOURCE + warehouse_idx,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const supervisionWarehouse = response.data
      return supervisionWarehouse;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }

  public static async getAll(id: number,navigate:NavigateFunction): Promise<Supervision[]|null> {
    try {
      const response: AxiosResponse<Supervision[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + "summary/" + id ,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const supervision = response.data.map((warehouse) =>
        Supervision.from(warehouse)
      );
      return supervision;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }

  public static async getDetails(warehouseidx: number,corridoridx: number,navigate:NavigateFunction): Promise<SupervisionDetails|null> {
    try {
      const response: AxiosResponse<SupervisionDetails> = await axios.get(
        API_BASE_URL + API_RESOURCE + warehouseidx + "/" + corridoridx + "/details",
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const supervisionDetails = response.data;
      return supervisionDetails;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }

  public static async getSupervisionHistories(warehouse_idx: number,navigate:NavigateFunction): Promise<SupervisionHistory[]|null> {
    try {
      const response: AxiosResponse<SupervisionHistory[]> = await axios.get(
        API_BASE_URL + API_RESOURCE+`histories/${warehouse_idx}`,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const supervisionHistory = response.data
      return supervisionHistory;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }

  public static async getSupervisionHistory(sweep_idx: number,navigate:NavigateFunction): Promise<SupervisionHistory|null> {
    try {
      const response: AxiosResponse<SupervisionHistory> = await axios.get(
        API_BASE_URL + API_RESOURCE +`history/${sweep_idx}`,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const supervisionHistory = response.data
      return supervisionHistory;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }

  public static async getSupervisionHistorySummary(warehouse_idx: number, sweep_idx: number,navigate:NavigateFunction): Promise<Supervision[]|null> {
    try {
      const response: AxiosResponse<Supervision[]> = await axios.get(
        API_BASE_URL + API_RESOURCE+`history/summary/${warehouse_idx}/${sweep_idx}`,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const supervisionHistorySummary = response.data
      return supervisionHistorySummary;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }

  public static async getSupervisionHistoryDetails(warehouse_idx: number, sweep_idx: number, corridor_idx:number,navigate:NavigateFunction): Promise<SupervisionDetails|null> {
    try {
      const response: AxiosResponse<SupervisionDetails> = await axios.get(
        API_BASE_URL + API_RESOURCE+`history/details/${warehouse_idx}/${sweep_idx}/${corridor_idx}`,
        {
          headers: UserService.getAuthHeader(),
        }
      );
      const supervisionHistoryDetails = response.data
      return supervisionHistoryDetails;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        notification.error({
          message: "Insufficient Credentials",
          description:"Update your credentials to access here"
        });
        if (error.response.status === 401) {
          sessionStorage.removeItem("token")
          navigate("/login")
          return null;
        }
      }
      return null;
    }
  }
}