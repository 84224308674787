import React, { useCallback, useEffect, useState } from "react";

import { CaretLeftOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import SupervisionService from "../../../../../services/SupervisionService";
import { SupervisionDetails } from "../../../../../models/Supervision";
import { Button, Card, Divider, Spin } from "antd";
import CorridorColorReferences from "./details/CorridorColorReferences";
import CorridorDetailsInfo from "./details/CorridorDetailsInfo";
import CorridorMap from "./details/CorridorMap";


const CorridorDetail = () => {
  const { warehouseIdx, sweepIdx, corridorIdx } = useParams();
  const [supervisionDetails, setsupervisionDetails] = useState<SupervisionDetails>();
  const [loading, setLoading] = useState(true);
  const navigate=useNavigate()
  const updateContent = useCallback(() => {
    setLoading(true)
    if (warehouseIdx && corridorIdx) {
      SupervisionService.getDetails(parseInt(warehouseIdx), parseInt(corridorIdx),navigate).then((supervisionDetails) => {
        if (supervisionDetails == null) {
          return;
        }
        setsupervisionDetails(supervisionDetails);
        setLoading(false);
      });
    }
  }, [corridorIdx, warehouseIdx,navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 180000);
    return () => clearInterval(interval);
  }, [updateContent]);


  return (
    <Spin spinning={loading}>
      <Card>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2>Corridor: {supervisionDetails?.corridor_name}</h2>
          <Link to={`/supervision/${warehouseIdx}`} >
            <Button type="primary" icon={<CaretLeftOutlined />} shape="round">
              Back
            </Button>
          </Link>
        </div>
        {supervisionDetails && <CorridorDetailsInfo supervisiondetails={supervisionDetails}
          loading={loading} />}
        <CorridorColorReferences />
        <Divider></Divider>
        {supervisionDetails && sweepIdx && <CorridorMap corridorIdx={corridorIdx} sweepIdx={sweepIdx} supervisiondetails={supervisionDetails} />}
      </Card>
    </Spin>
  );
};

export default CorridorDetail;
