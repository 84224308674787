import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import UserContext from "../context/UserContext";
import  { UserShow } from "../models/User";

const PrivateRoutes = () => {
  const [currentUser, setCurrentUser] = useState<UserShow | undefined>(); // initially undefined
  const navigate = useNavigate();
  const prevLocation = useLocation();

  //GUARD
  useEffect(() => {
    UserService.validateAccessToken()
      .then((user: UserShow) => {
        setCurrentUser(user);
      })
      .catch((err) => {
        setCurrentUser(undefined);
        if (err.status === 401) {
          navigate(`/login?redirectTo=${prevLocation.pathname}`);
        } else {
          navigate("/500");
        }
      });
  }, [navigate, prevLocation]);
  if (currentUser === undefined) {
    return null;
  }
  return currentUser ? (
    <UserContext.Provider value={currentUser}>
      <Outlet />
    </UserContext.Provider>
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoutes;
