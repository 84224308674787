import { useCallback, useEffect, useMemo, useState } from "react";
import { Divider } from "antd";
import SummaryTable from "./SummaryTable";
import { SweepPositionShow } from "../../../../../../models/Sweep";
import PositionsControls from "./PositionsControls";

interface PositionsProps {
  sweepId: number;
  onSort?: ((column_key: string, order: string) => void) | undefined;
  repeatedPositions: string[];
  onUpdateContent: () => void;
  positions: SweepPositionShow[];
  sweepStatus: string
}

const Positions = (props: PositionsProps) => {
  const [positions, setPositions] = useState<SweepPositionShow[]>([]);
  const [positionFilter, setPositionFilter] = useState<string>("");
  const [palletFilter, setPalletFilter] = useState<string>("");
  const [corridorFilter, setCorridorFilter] = useState<string>("");
  const [columnFilter, setColumnFilter] = useState<string>("");
  const [levelFilter, setLevelFilter] = useState<string>("");
  const [displayMatchedsFilter, setDisplayMatchedsFilter] = useState<boolean>(true);
  const [displayUnMatchedsFilter, setDisplayUnMatchedsFilter] = useState<boolean>(false);
  const [displayUnexploredsFilter, setDisplayUnexploredsFilter] = useState<boolean>(false);
  
  
  const onApplyFilters = useCallback(
    (
      position: string,
      pallet: string,
      corridor: string,
      column: string,
      level: string,
      displayMatches: boolean,
      displayUnMatches: boolean,
      displayUnexploredsFilter: boolean
    ) => {
      setPositionFilter(position);
      setPalletFilter(pallet);
      setCorridorFilter(corridor);
      setColumnFilter(column);
      setLevelFilter(level);
      setDisplayMatchedsFilter(displayMatches);
      setDisplayUnMatchedsFilter(displayUnMatches);
      setDisplayUnexploredsFilter(displayUnexploredsFilter);
    },
    []
  );

  const trimSpacesInPositionLabels = (sweepPositions: SweepPositionShow[]): SweepPositionShow[] => {
    return sweepPositions.map(position => {
      position.position_label = position.position_label.trim();
      return position;
    });
  };
  const updateContent = useCallback(() => {
    setPositions(trimSpacesInPositionLabels(props.positions));
  }, [props.positions]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 600000);
    return () => clearInterval(interval);
  }, [updateContent]);
  const filteredPositions = useMemo(() => {
    let filtered = positions;
    if (positionFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position_label
          .toLocaleUpperCase()
          .includes(positionFilter.toLocaleUpperCase())
      );
    }
    if (palletFilter !== "" ) {
      filtered = filtered.filter((entry) => {
        if (entry.pallet_label === undefined || entry.pallet_label === null) {
          return "empty"
            .toLocaleUpperCase()
            .includes(palletFilter.toLocaleUpperCase());
        }
        return entry.pallet_label
          .toLocaleUpperCase()
          .includes(palletFilter.toLocaleUpperCase());
      });
    }
    if (corridorFilter !== "" && positionFilter === "") {
      filtered = filtered.filter((entry) =>
        entry.position_label
          .toLocaleUpperCase()
          .slice(0, entry.position_label.length-3)
          .includes(corridorFilter.toLocaleUpperCase())
      
      );
    }
    if (columnFilter !== "" && positionFilter === "") {
      filtered = filtered.filter((entry) =>
          entry.position_label
            .toLocaleUpperCase()
            .slice(entry.position_label.length-3, entry.position_label.length-1)
            .includes(columnFilter.toLocaleUpperCase())
        
      );
    }
    if (levelFilter !== "" && positionFilter === "") {
      filtered = filtered.filter((entry) =>
        entry.position_label[entry.position_label.length-1]
          .includes(levelFilter)
      
      );
    }
    if (displayMatchedsFilter || displayUnMatchedsFilter || displayUnexploredsFilter){
      filtered = filtered.filter((entry) => 
        ((entry.state === "full" || entry.state === "empty") && displayMatchedsFilter) ||
        ((entry.state === "invalid") && displayUnMatchedsFilter) ||
        ((entry.state === "unknown") && displayUnexploredsFilter)
      );
    }
    return filtered;
  }, [
    positions,
    positionFilter,
    palletFilter,
    corridorFilter,
    columnFilter,
    levelFilter,
    displayMatchedsFilter,
    displayUnMatchedsFilter,
    displayUnexploredsFilter
  ]);
  return ( 
    <>
      <PositionsControls onApply={onApplyFilters} />
      <Divider />
      <SummaryTable positions={filteredPositions}  onSort={props.onSort}
        sweepStatus = {props.sweepStatus}
        sweepId={props.sweepId ? props.sweepId : 0}
        onUpdateContent={props.onUpdateContent}
        repeatedPositions={props.repeatedPositions} />
    </>
  );
};

export default Positions;