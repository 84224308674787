import { DownloadOutlined } from "@ant-design/icons";
import { Image, Spin } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

interface ImageDisplayerProps {
  fetchFunction: () => Promise<Blob | null>;
  lowfetchFuntion?: (abortSignal: AbortSignal) => Promise<Blob | null>;
  style?: React.CSSProperties;
  rotate?: boolean;
  height?: string;
  width?: string;
}

function ImageDisplayer(props: ImageDisplayerProps) {
  const [image, setImage] = useState<string | undefined>(undefined);
  const [lowImage, setLowImage] = useState<string | undefined>(undefined);
  const [loadingLowImage, setLoadingLowImage] = useState<boolean>(false);

  // This ref will store the current request to ensure only the last one is processed
  const controllerRef = useRef<AbortController | null>(null);
  
  const updatelowImage = useCallback(async () => {
    if (!props.lowfetchFuntion) return;

    // Abort the previous request if it exists
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    // Create a new AbortController and assign it to the ref
    const controller = new AbortController();
    controllerRef.current = controller;

    setLoadingLowImage(true);

    try {
      const imageBlob = await props.lowfetchFuntion(controller.signal);

      if (imageBlob === null || controller.signal.aborted) {
        return;
      }

      const imageUrl = URL.createObjectURL(imageBlob);
      setLowImage(imageUrl);
      
      return () => {
        URL.revokeObjectURL(imageUrl);
      };
    } catch (error) {
        console.error(error);
    } finally {
      // Only stop loading if the request was not aborted
      if (!controller.signal.aborted) {
        setLoadingLowImage(false);
      }
    }// eslint-disable-next-line
  }, [props.lowfetchFuntion]);

  const updateImage = useCallback(async () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    try {
      const imageBlob = await props.fetchFunction();
      if (imageBlob === null || controller.signal.aborted) return;

      const imageUrl = URL.createObjectURL(imageBlob);
      setImage(imageUrl);
      
      return () => URL.revokeObjectURL(imageUrl);
    } catch (error) {
        console.error(error);
    }// eslint-disable-next-line
  }, [props.fetchFunction]);

  useEffect(() => {
    updatelowImage();
    return () => {
      // Cleanup to ensure no memory leaks when component unmounts
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [updatelowImage]);

  const onDownload = (imgUrl: string | undefined) => {
    if (!imgUrl) return;
    
    const link = document.createElement('a');
    link.href = imgUrl;
    link.download = 'image.jpg';
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(imgUrl);
    link.remove();
  };

  const handlePreviewClose = () => {
    if (image) {
      URL.revokeObjectURL(image);
      setImage(undefined); // Clean up the state as well
    }
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {loadingLowImage && (
        <Spin
          spinning={loadingLowImage}
          style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
        />
      )}
      <Image
        src={lowImage}
        style={{
          borderRadius: "10px",
          textAlign: "center",
          ...props.style,
          transform: props.rotate ? "rotate(90deg)" : undefined,
          opacity: loadingLowImage ? 0 : 1,
          transition: "opacity 0.3s ease-in-out",
        }}
        onLoad={() => setLoadingLowImage(false)}
        preview={{
          toolbarRender: (actions) => (
            <DownloadOutlined onClick={() => onDownload(image)} />
          ),
          destroyOnClose: true,
          onVisibleChange: (visible) => {
            if (!visible) handlePreviewClose(); // Call handlePreviewClose when the preview is closed
            else updateImage(); // Update image when preview opens
          },
          imageRender: () =>
            image ? <Image src={image} preview={false} /> : <Spin spinning={true} />,
        }}
        height={props.height}
        width={props.width}
      />
    </div>
  );
}

export default ImageDisplayer;
