import { PlusOutlined, ExclamationCircleOutlined, SettingOutlined, WifiOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Row, Spin, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import PalletDeviceService from "../../../../services/PalletDeviceService";
import { PalletDeviceConnections, PalletDeviceDetails } from "../../../../models/PalletDevice";
import HardwareInfo from "./subcomponents/HardwareInfo";
import { WarehouseSummary } from "../../../../models/Warehouse";
import WarehouseService from "../../../../services/WarehouseService";
import DeviceCreateModal from "./DeviceCreateModal";
import DeviceModifyModal from "./DeviceModifyModal";
import ResizeObserver from 'resize-observer-polyfill';
import { useNavigate } from "react-router-dom";

const Devices: React.FC = () => {
  const [palletDevices, setPalletDevices] = useState(new Array<PalletDeviceDetails>());
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
  const [warehouses, setWarehouses] = useState<WarehouseSummary[]>([]);
  const [warehouseIdx, setWarehouseIdx] = useState<number | undefined>(-1);
  const [selectedPalletDevice, setSelectedPalletDevice] = useState<PalletDeviceDetails | undefined>(undefined);
  const [devicesConnections, setDevicesConnections] = useState<PalletDeviceConnections[]>([]);
  const [parentSizes, setParentSizes] = useState<Record<number, { width: number, height: number }>>({});
  const navigate=useNavigate()
  const updateContent = useCallback(() => {
    PalletDeviceService.getAll().then((palletDevices) => {
      if (palletDevices == null) {
        return;
      }
      setPalletDevices(palletDevices);
      WarehouseService.getAll(navigate).then((warehouses) => {
        if (warehouses == null) {
          return;
        }
        const no_warehouse = new WarehouseSummary();
        no_warehouse.name = "No Warehouse";
        no_warehouse.idx = -1;
        setWarehouses([...warehouses, no_warehouse]);
        setLoading(false);
      });
    });
    PalletDeviceService.getConnections().then((connections) => {
      if (connections == null) {
        return;
      }
      setDevicesConnections(connections);
    });
  }, [navigate]);

  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 50000);
    return () => clearInterval(interval);
  }, [updateContent]);

  const handleResize = (idx: number, entry: ResizeObserverEntry) => {
    setParentSizes((prevSizes) => ({
      ...prevSizes,
      [idx]: {
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      },
    }));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showModifyModal = (palletDevice: PalletDeviceDetails) => {
    setSelectedPalletDevice(palletDevice);
    setIsModifyModalOpen(true);
  };
  const closeModifyModal = () => {
    setIsModifyModalOpen(false);
  };

  const handleWarehouseChange = (value: number) => {
    setWarehouseIdx(value);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Card
          title={"Pallet Devices"}
          extra={
            <>
              <Button
                type="primary"
                onClick={showModal}
                icon={<PlusOutlined />}
                shape="round"
              >
                Add
              </Button>
              <DeviceCreateModal
                onClose={closeModal}
                onUpdateContent={updateContent}
                isLoading={loading}
                warehouseIdx={warehouseIdx}
                onSetWarehouseIdx={setWarehouseIdx}
                warehouses={warehouses}
                palletDevices={palletDevices}
                isOpen={isModalOpen}
                onWarehouseChange={handleWarehouseChange}
              />
            </>
          }
        >
          <Row gutter={[16, 16]} style={{ overflow: "auto" }}>
            {palletDevices.map((palletDevice, i) => (
              <Col key={i} span={8} style={{ overflow: "auto" }}>
                <Card
                  title={
                    <div style={{ fontSize: "1.3vw" }}>
                      {palletDevice.name}{" "}
                      {devicesConnections.find((device) => device.idx === palletDevice.idx)?.is_connected ? (
                        <Tag style={{ left: "5px", fontSize: "0.95vw" }} icon={<WifiOutlined />} color="success">
                          online
                        </Tag>
                      ) : (
                        <>
                          <Tag style={{ left: "5px", fontSize: "0.95vw" }} icon={<ExclamationCircleOutlined />} color="default">
                            offline
                          </Tag>
                          <div style={{ fontSize: "80%", color: "#7a848d" }}>
                            since {devicesConnections.find((device) => device.idx === palletDevice.idx)?.timestamp}
                          </div>
                        </>
                      )}
                    </div>
                  }
                  ref={(el) => {
                    if (el) {
                      const observer = new ResizeObserver((entries) => {
                        for (let entry of entries) {
                          if (entry.target === el) {
                            handleResize(i, entry);
                          }
                        }
                      });
                      observer.observe(el);
                    }
                  }}
                  style={{ minHeight: "270px", overflow: "auto" }}
                  extra={
                    <Button
                      type="primary"
                      onClick={() => showModifyModal(palletDevice)}
                      shape="round"
                      size={window.innerWidth < 900 ? "small" : "middle"}
                    >
                      <SettingOutlined />
                    </Button>
                  }
                >
                  {palletDevice.hardware_status ? (
                    <HardwareInfo
                      parentWidth={parentSizes[i]?.width || 0}
                      parentHeight={parentSizes[i]?.height || 0}
                      hardwareStatus={palletDevice.hardware_status}
                      loading={loading}
                    />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
        <DeviceModifyModal
          onClose={closeModifyModal}
          onUpdateContent={updateContent}
          isLoading={loading}
          palletDevice={selectedPalletDevice}
          warehouses={warehouses}
          palletDevices={palletDevices}
          isOpen={isModifyModalOpen}
        />
      </Spin>
    </>
  );
};

export default Devices;
