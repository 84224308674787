import React from "react";
import { Tag, Divider } from "antd";

const CorridorColorReferences = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <Divider orientation="center">Color References</Divider>
      <Tag color="#4CAF50">Full</Tag>
      <Tag color="#FFC107">Empty</Tag>
      <Tag color="#2196F3">Unexplored</Tag>
      <Tag color="#FF5252">Ignored</Tag>
    </div>
  );
};

export default CorridorColorReferences;