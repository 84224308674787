import { Button, Modal, notification, Table, Tag } from "antd";
import { Breakpoint } from "antd";
import {
  AreaChartOutlined,
  CheckCircleOutlined,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  MinusCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import SweepBase from "../../../../../models/Sweep";
import SweepService from "../../../../../services/SweepService";
import { Link } from "react-router-dom";


interface SweepsTableProps {
  sweeps: SweepBase[];
  onSort?: (column_key: string, order: string) => void;
  onUpdateContent: () => void;
  writePermission:Boolean;
}

const SweepsTable = (props: SweepsTableProps) => {
  const { confirm } = Modal;
  const showConfirm = (type: string, handler: (idx: number) => void, idx: number) => {
    confirm({
      title: `Do you want to ${type} these Sweep?`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        console.log('OK');
        handler(idx)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const [sweepStatus] = useState<{ [key: string]: string }>({});
  const [pageSize, setPageSize] = useState<number>(10);

  const handleEnd = async (idx: number) => {
    try {
      var sweepToUpdate = props.sweeps.find(sweep => sweep.idx === idx);
      if (sweepToUpdate === undefined) {
        return;
      }
      sweepToUpdate.status = "processed"
      SweepService.updateStatus(sweepToUpdate).then(() => {
        props.onUpdateContent();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleStop = async (idx: number) => {
    try {
      var sweepToUpdate = props.sweeps.find(sweep => sweep.idx === idx);
      if (sweepToUpdate === undefined) {
        return;
      }
      sweepToUpdate.status = "stopped"
      SweepService.updateStatus(sweepToUpdate).then(() => {
        props.onUpdateContent();
      });
    } catch (error) {
      console.error(error);
    }
  }
  const handleCSV = (idx: string) => {
    SweepService.statusCSV(idx).then((status) => {
      if (status != null && status === true) {
        SweepService.exportCSV(idx)
      }
      else {
        if (status === false) {
          notification.info({
            message: "CSV is not ready",
            description: `CSV for sweep ${idx} is still being generated`,
          });
        }
      }
    })
  }


  const getStatusTag = (status: string) => {
    let tagIcon;
    let tagColor;
    let showstatus;
    switch (status) {
      case "processing":
        tagIcon = <SyncOutlined spin />;
        tagColor = "blue";
        showstatus = "processing"
        break;
      case "processed":
        tagIcon = <CheckCircleOutlined />;
        tagColor = "green";
        showstatus = "finished";
        break;
      case "stopped":
        tagIcon = <MinusCircleOutlined />;
        tagColor = "red";
        showstatus = "cancelled";

        break;
      default:
        tagIcon = null;
        tagColor = "default";
    }
    return <Tag icon={tagIcon} color={tagColor}>{showstatus}</Tag>;
  };

  const columns = [
    {
      title: "",
      key: "idx",
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.idx || "",
    },
    {
      title: "Warehouse",
      key: "warehouse",
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.warehouse?.name || "",
    },
    {
      title: "Counting number",
      key: "counting_number",
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.counting_number || "",
    },
    {
      title: "Date",
      key: "timestamp_start",
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.timestamp_start.slice(0, 11) || "",
    },
    {
      title: "Devices",
      key: "devices",
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.devices.length,
    },
    {
      title: "Readings count",
      key: "readings_count",
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => item.readings_count || "0",
    },
    {
      title: "Status",
      key: "status",
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => getStatusTag(sweepStatus[item.idx] || item.status)
    },
    {
      title: "Action",
      key: "action",
      responsive: ["lg"] as Breakpoint[],
      render: (item: SweepBase) => (
        <>
          <Button
            disabled={item.status !== "processing"||!props.writePermission}
            type="primary"
            icon={<CheckCircleOutlined />}
            shape="round"
            style={{ margin: "1em" }}
            onClick={() => showConfirm("finish", handleEnd, item.idx)}
          >
            Finish
          </Button>
          <Button
            disabled={item.status !== "processing"||!props.writePermission}
            type="primary"
            icon={<StopOutlined />}
            shape="round"
            onClick={() => showConfirm("cancel", handleStop, item.idx)}
          >
            Cancel
          </Button>
        </>
      ),
    },
    {
      title: "Details",
      key: "details",
      render: (item: SweepBase) => (
        <Link to={`${item.idx}`}>
          <Button type="primary" icon={<InfoCircleOutlined />} shape="round">
            Details
          </Button>
        </Link>
      ),
    },
    {
      title: "CSV",
      key: "csv",
      render: (item: SweepBase) => (
        <Button type="primary"
          // icon={<InfoCircleOutlined />} 
          shape="round"
          onClick={() => handleCSV(String(item.idx))}>
          CSV
        </Button>
      ),
    },
    // {
    //   title: "Revision",
    //   key: "revision",
    //   render: (item: SweepBase) => (
    //     <Link to={`${item.idx}/revision/`}>
    //       <Button type="primary" disabled={item.status === "processed" ? false : true} icon={<FormOutlined />} shape="round">
    //         Revision
    //       </Button>
    //     </Link>
    //   ),
    // },
    {
      title: "Supervision",
      key: "supervision",
      render: (item: SweepBase) => (
        item.status === "processing" ?
          <Link to={`../supervision/${item.warehouse?.idx}`}>
            <Button type="primary" disabled={item.status === "processing" ? false : true} icon={<AreaChartOutlined />} shape="round">
              Supervision
            </Button>
          </Link>
          :
          <Link to={`../supervision/history/${item.warehouse?.idx}/${item.idx}`}>
            <Button type="primary" disabled={item.status === "processed" ? false : true} icon={<AreaChartOutlined />} shape="round">
              Supervision
            </Button>
          </Link>
      ),

    },
  ];
  const comparator = (a: SweepBase, b: SweepBase) => {
    const dateA = new Date(a.timestamp_start);
    const dateB = new Date(b.timestamp_start);

    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;
    if (a.warehouse && b.warehouse) {
      if (a.warehouse.name < b.warehouse.name) return -1;
      if (a.warehouse.name > b.warehouse.name) return 1;
    }
    return 0;
  };

  const sortedSweeps = props.sweeps.sort(comparator);


  console.log(sortedSweeps);

  const paginationConfig = {
    pageSize: pageSize,
    total: props.sweeps.length,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"],
    onChange: (page: number, pageSize: number | undefined) => {
      if (pageSize) {
        setPageSize(pageSize);
      }
    }
  }

  return (
    <Table
      columns={columns}
      dataSource={sortedSweeps}
      rowKey="id"
      scroll={{ x: true }}
      size="small"
      pagination={paginationConfig}
    />
  );
};

export default SweepsTable;