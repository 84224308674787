import { Table } from "antd";
import { Breakpoint } from "antd";
import Position from "../../../../../../models/Position";
import moment from 'moment';
import React from "react";
import { WarehousePictureService } from "../../../../../../services/PictureService";
import ImageDisplayer from "../../../../../utils/ImageDisplayer";
import { WarehouseThumbnailService } from "../../../../../../services/ThumbnailService";


interface PositionsTableProps {
  positionsCount: number;
  page: number;
  pageSize: number;
  onPageChange: (page:number,pageSize:number) => void;
  positions: Position[];
  loading: boolean;
  warehouseId: number;
}

const PositionsTable = (props: PositionsTableProps) => {
  const columns = [
    // {
    //   title: "",
    //   key: "idx",
    //   responsive: ["lg"] as Breakpoint[],
    //   render: (item: Position) => item.idx,
    // },
    {
      title: "Position Label",
      key: "position_label",
      responsive: ["lg"] as Breakpoint[],
      render: (item: Position) => item.position_label,
    },
    {
      title: "Position Image",
      key: "image",
      render: (item: Position) => {
          return (
            <ImageDisplayer
              fetchFunction={() => {
                const paddedPositionLabel = item.position_label.padStart(5, ' ');
                return WarehousePictureService.getWarehousePositionPicture(props.warehouseId, paddedPositionLabel);
              }}
              lowfetchFuntion={() => {
                const paddedPositionLabel = item.position_label.padStart(5, ' ');
                return WarehouseThumbnailService.getWarehousePositionThumbnail(props.warehouseId, paddedPositionLabel);
              }}
              height={"50px"}
              width={"88.88px"}
            />
          );
      },
    },
    {
      title: "Pallet Label",
      key: "positions",
      responsive: ["lg"] as Breakpoint[],
      render: (item: Position) => item.pallet_label,
    },
    {
      title: "Pallet Image",
      key: "image",
      render: (item: Position) => {
          return (
            <ImageDisplayer
              fetchFunction={() => {
                const paddedPositionLabel = item.position_label.padStart(5, ' ');
                return WarehousePictureService.getWarehousePalletPicture(props.warehouseId, paddedPositionLabel);
              }}
              lowfetchFuntion={() => {
                const paddedPositionLabel = item.position_label.padStart(5, ' ');
                return WarehouseThumbnailService.getWarehousePalletThumbnail(props.warehouseId, paddedPositionLabel);
              }}
              height={"50px"}
              width={"88.88px"}
            />
      )},
    },
    {
      title: "Last Update",
      key: "last_update",
      responsive: ["lg"] as Breakpoint[],
      render: (item: Position) => item.last_update ? moment(item.last_update).format('YYYY-MM-DD HH:mm:ss') : "",
    },
    {
      title: "State",
      key: "state",
      responsive: ["lg"] as Breakpoint[],
      render: (item: Position) => item.state,
    },
  ];

  const paginationConfig = {
    pageSize: props.pageSize,
    total: props.positionsCount,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50"],
    onChange: (page: number, pageSize: number | undefined) => {
      if (pageSize) {
        props.onPageChange(page,pageSize);
      }
    },
  };

  return (
    <Table
      columns={columns}
      dataSource={props.positions}
      loading={props.loading}
      rowKey="id"
      scroll={{ x: true }}
      size="small"
      pagination={paginationConfig}
      showSorterTooltip={true}
    />
  );
};

export default PositionsTable;